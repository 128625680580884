import React from 'react';
import '../componentsCss/UiProject.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const UiProjectView = () => {
    return (
        <div className="UiProject-section">
            <div className="UiProject-photo-section">
                <div className="UiProject-card1">
                </div>

                <span className="UiProject-card2"></span>
                <span className='UiProject-card3'></span>

                <span className='UiProject-card4'></span>
                <span className='UiProject-card5'></span>
            </div>
            <div className="description-section">
                <p>
                    The website features a sleek, modern UI design tailored for the real estate industry, exclusively in dark mode. This aesthetic choice enhances visual appeal while ensuring an optimal user experience and functionality.
                </p>
            </div>

        </div>
    );
};

export default UiProjectView;
