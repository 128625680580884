import React from 'react';
import '../componentsCss/sneakersAppProject.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const SneakerAppPage = () => {
  return (
    <div className="sneaker-section">
      <div className="sneaker-photo-section">
        <div className="sneaker-card1">
          <h2 className="sneaker-heading">Intuitive interface</h2>
        </div>

        <div className="sneaker-card2">
          <h2 className="sneaker-heading">Robust features</h2>
        </div>
      </div>
      <div className="description-section">
        <p>
          Discover your perfect sneakers with our app, featuring detailed views
          and immersive 360-degree 3D models. Explore every angle and find the
          shoes that match your style and comfort.
        </p>
      </div>

    </div>
  );
};

export default SneakerAppPage;
