import React from 'react';
import '../componentsCss/Card.css';

function Card({ title, icon, borderColor }) {
    return (
        <div className="card" style={{ '--card-border-color': borderColor, borderColor: borderColor}}>
            <h3>{title}</h3>
            <div className="icon">
                <img src={icon} alt="icon" />
            </div>
        </div>
    );
}

export default Card;
