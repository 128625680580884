import React from 'react';
import '../componentsCss/HomeView.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Spline from '@splinetool/react-spline';

function HomeView() {
    return (
        <div className="App">
            <header className="App-header">
                <div className="hero-section">
                    <div className="hero-image laptop-view">
                        <Spline scene="https://prod.spline.design/TpIPFfDZMokb5VhV/scene.splinecode"/>
                    </div>
                    <div className="hero-image mobile-view">
                        <img src="mobileBackground.png" alt="Hero" width={"100%"}/>
                    </div>
                    <div className="hero-text">
                        <h1>Your Vision,<br />Our Expertise,<br />Infinite Potential!</h1>
                        <p>At Elevvia, we specialize in creating exceptional digital experiences. Our team of seasoned developers, designers, and AI experts is dedicated to bringing your vision to life.</p>

                        <h2>Our mission</h2>
                        <p>is to empower individuals/small businesses with cutting-edge technology and design, helping them thrive in the digital age.</p>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default HomeView;
