import 'bootstrap/dist/css/bootstrap.css';
import HomeView from './components/HomePage';
import NavBarView from './components/NavBar';
import ProjectView from './components/ProjectsView';
import ServicesOffered from './components/ServicesOffered';
import Footer from './components/footer';
import ContactUs from './components/ContactUs';
import FAQ from './components/faq';

function App() {
  return (
    <div style={{ backgroundColor: 'rgb(242, 244, 248)', minHeight: '100vh', margin: 0, paddingTop: "80px" }}>
      <NavBarView />
      <HomeView />
      <ProjectView />
      <ServicesOffered />
      <ContactUs />
      <FAQ />
      <Footer />
    </div>
  );
}

export default App;
