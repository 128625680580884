import React, { useState } from 'react';
import '../componentsCss/faq.css'; // Import CSS file for styling

const FAQ = () => {
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [showAll, setShowAll] = useState(false);

    const faqs = [
        {
            question: 'What services does your agency provide?',
            answer: [
                'Web Development: Building responsive, scalable websites and web applications.',
                'iOS App Development: Creating high-performance, user-friendly iOS applications.',
                'AI Solutions: Implementing AI-driven solutions for business automation, data analysis, and more.',
                'UI/UX Design: Designing intuitive and engaging user interfaces and experiences.'
            ]
        },
        {
            question: 'What industries do you specialize in?',
            answer: [
                'We serve a wide range of industries, including healthcare, e-commerce, education, real estate, finance, and more. Our diverse experience allows us to tailor solutions to meet the unique needs of each industry.'
            ]
        },
        {
            question: 'How do you approach a new project?',
            answer: [
                'Initial Consultation: Understanding your business needs and goals.',
                'Planning & Strategy: Defining the project scope, timeline, and deliverables.',
                'Design & Development: Creating prototypes, UI/UX designs, and building the solution.',
                'Testing & Deployment: Thoroughly testing the product and launching it.',
                'Post-Launch Support: Offering ongoing maintenance and support.'
            ]
        },
        {
            question: 'What technologies do you use?',
            answer: [
                'We use the latest technologies to ensure top-quality results. For web development, we utilize HTML/CSS, JavaScript, React, Node.js, and more.',
                'Our iOS apps are developed using Swift and SwiftUI.',
                'Our AI solutions leverage Python, TensorFlow, and other advanced tools.',
                'Our UI/UX designs are crafted using Figma, Adobe XD, and Sketch.'
            ]
        },
        {
            question: 'How long does it take to complete a project?',
            answer: [
                'The timeline varies depending on the project\'s complexity and scope. A typical web development project might take 4-8 weeks, while a custom iOS app could take 8-12 weeks. We provide a detailed timeline after the initial consultation.'
            ]
        },
        {
            question: 'Can you work with our existing team or systems?',
            answer: [
                'Yes, we are flexible and can seamlessly integrate with your existing team or systems. Whether you need us to handle the entire project or collaborate with your in-house team, we\'re here to support your needs.'
            ]
        },
        {
            question: 'How do you ensure the security and confidentiality of our data?',
            answer: [
                'We take data security and confidentiality very seriously. We implement industry-standard security practices, including encryption, secure coding practices, and regular audits.',
                'We are also open to signing Non-Disclosure Agreements (NDAs) to protect your intellectual property.'
            ]
        },
        {
            question: 'Do you offer post-launch support and maintenance?',
            answer: [
                'Yes, we provide post-launch support and maintenance to ensure your product remains up-to-date, secure, and functional. Our support packages can be tailored to your specific needs.'
            ]
        },
        {
            question: 'What is your pricing structure?',
            answer: [
                'Our pricing is project-based and depends on the scope and complexity of the work. We offer transparent pricing and provide detailed quotes after understanding your requirements.',
                'We also offer flexible payment plans to accommodate different budgets.'
            ]
        },
        {
            question: 'How can we get started?',
            answer: [
                'To get started, simply contact us through our website or email us at [your contact email]. We’ll schedule an initial consultation to discuss your project and how we can help bring your vision to life.'
            ]
        },
        {
            question: 'Do you have examples of your previous work?',
            answer: [
                'Yes, we have a portfolio of our previous projects available on our website. You can explore case studies and examples of our work across different industries and services.'
            ]
        },
        {
            question: 'What makes your agency different from others?',
            answer: [
                'Our agency stands out due to our deep technical expertise, client-focused approach, and commitment to delivering high-quality solutions.',
                'We take the time to understand your business goals and work closely with you throughout the project to ensure your success.'
            ]
        }
    ];
    const displayedFAQs = showAll ? faqs : faqs.slice(0, 5);

    const handleToggle = () => {
        setShowAll(prevState => !prevState);
    };

    return (
        <div className="faq-container">
            <h1>Frequently Asked Questions</h1>
            <div className="faq-list">
                {displayedFAQs.map((faq, index) => (
                    <div key={index} className="faq-item">
                        <div
                            className="faq-question"
                            onClick={() => setExpandedIndex(expandedIndex === index ? null : index)}
                        >
                            <h5>{faq.question}</h5>
                            <span>{expandedIndex === index ? '-' : '+'}</span>
                        </div>
                        <div
                            className={`faq-answer ${expandedIndex === index ? 'open' : ''}`}
                        >
                            {faq.answer.map((ans, idx) => (
                                <p key={idx}>{ans}</p>
                            ))}
                        </div>
                    </div>
                ))}
                {faqs.length > 6 && (
                    <div className="more-button" onClick={handleToggle}>
                        {showAll ? 'Show Less' : 'Show More'}
                    </div>
                )}
                {!showAll && faqs.length > 6 && (
                    <div className="fade-effect"></div>
                )}
            </div>
        </div>
    );
};

export default FAQ;
