import React from 'react';
import '../componentsCss/tourProject.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const TourView = () => {
    return (
        <div className="tour-section">
            <div className="tour-description-section">
                <p>
                    Begin your next journey with our travel packages and explore unique 'Adventure Ideas!' tailored for thrill-seekers, offering unforgettable experiences to satisfy your wanderlust and create lasting memories.
                </p>
            </div>

            <div className="tour-photo-section">
                <div className="tour-card1">
                    <h2 className="tour-heading">Travel Safe</h2>
                </div>

                <div className="tour-card2">
                    <h2 className="tour-heading">Robust features</h2>
                </div>

                <div className="tour-card3">
                    <h2 className="tour-heading">Quick Look</h2>
                </div>
            </div>

        </div>
    );
};

export default TourView;
