import React from "react";
import "../componentsCss/footer.css";

function Footer() {
    return (
        <nav className="footer navbar-light">
            <div className="footer-container-fluid">
                <p><a href="mailto:Elevvia.tech@gmail.com">Elevvia.tech@gmail.com</a></p>
            </div>

            <div className="social-media">
                <a href="https://www.instagram.com/elevvia.tech?igsh=Mnh4ZTRsNWs3Zmtz">
                    <img src="insta.png" alt="instagram-Logo" width="30" height="30"></img>
                </a>
                <a href="https://www.linkedin.com/in/elevvia-tech-805a49325/" target="/blank">
                    <img src="linkedln.png" alt="linkedln-Logo" width="25" height="25"></img>
                </a>
                <a href="/">
                    <img src="facebook.png" alt="facebook-Logo" width="30" height="30"></img>
                </a>
            </div>
        </nav>
    );
}

export default Footer;
