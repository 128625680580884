import React, { useRef, useEffect } from 'react';
import Card from './Card';
import UpperDemoCardView from './upperDemoCard';
import '../componentsCss/ServicesOffered.css';

function ServicesOffered() {
    const sectionRef = useRef(null);
    const cardsRef = useRef([]);

    useEffect(() => {
        const handleScroll = () => {
            if (sectionRef.current) {
                const sectionPos = sectionRef.current.getBoundingClientRect();
                const windowHeight = window.innerHeight;
                const viewportWidth = window.innerWidth;

                // Check if the section is at least 0% visible
                const sectionHeight = sectionRef.current.offsetHeight;
                const visiblePercentage = Math.min(1, Math.max(0, (windowHeight - sectionPos.top) / (sectionHeight + windowHeight)));

                if (viewportWidth > 768) {
                    // Laptop view animation
                    if (visiblePercentage >= 0.3) {
                        cardsRef.current.forEach((card, index) => {
                            const offset = Math.min(100, (window.scrollY - sectionPos.top) / 10);
                            if (index % 2 === 0) {
                                card.style.transform = `translateY(${offset}px)`;
                            } else {
                                card.style.transform = `translateY(-${offset}px)`;
                            }
                        });
                    } else {
                        cardsRef.current.forEach((card) => {
                            card.style.transform = `translateY(0)`;
                        });
                    }
                } else {
                    // Phone view animation: Cards appear one by one
                    cardsRef.current.forEach((card, index) => {
                        const cardTop = card.getBoundingClientRect().top;
                        if (cardTop < windowHeight * 0.9) {
                            card.style.opacity = '1';
                            card.style.transform = 'translateY(0)';
                            card.style.transition = `opacity 0.5s ease ${index * 0.2}s, transform 0.5s ease ${index * 0.2}s`;
                        } else {
                            card.style.opacity = '0';
                            card.style.transform = 'translateY(50px)';
                        }
                    });
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className='services-main' ref={sectionRef}>
            <h1>Services We Offer</h1>
            <div className="grid-container">
                <div ref={(el) => (cardsRef.current[0] = el)}>
                    <UpperDemoCardView />
                    <Card
                        title="iOS App Development using apple native language"
                        icon="swift.png"
                        borderColor="orange"
                    />
                    <div className="lower-demo-card"></div>
                </div>
                <div ref={(el) => (cardsRef.current[1] = el)}>
                    <UpperDemoCardView />
                    <Card
                        title="Develope Websites using latest technologies"
                        icon="webDev.png"
                        borderColor="purple"
                    />
                    <div className="lower-demo-card"></div>
                </div>
                <div ref={(el) => (cardsRef.current[2] = el)}>
                    <UpperDemoCardView />
                    <Card
                        title="Create AI Generated Models"
                        icon="AiModel.png"
                        borderColor="orange"
                    />
                    <div className="lower-demo-card"></div>
                </div>
                <div ref={(el) => (cardsRef.current[3] = el)}>
                    <UpperDemoCardView />
                    <Card
                        title="UI/UX for your web / Mobile Apps"
                        icon="uiux.png"
                        borderColor="purple"
                    />
                    <div className="lower-demo-card"></div>
                </div>
            </div>
        </div>
    );
}

export default ServicesOffered;
