import React, { useState } from 'react';
import '../componentsCss/ContactUs.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import emailjs from 'emailjs-com';  // Import EmailJS
import toast from 'react-hot-toast';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    serviceType: "",
    details: "",
  });
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.send('service_d2kftvs', 'template_z44zazs', formData, 'goYr5RJOO8drAMme0')
      .then((response) => {
        setMessage(toast.success('Sent Successfully'));
        setFormData({
          name: "",
          email: "",
          serviceType: "",
          details: "",
        });
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        setMessage('Error sending information. Please try again.');
      });
  };

  return (
    <div className="contact-App">
      <h2>Get in Touch</h2>
      <header className="contact-App-header">
        <video autoPlay muted loop className="background-video">
          <source src="/contact-background.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="contact-hero-section">
          <div className="contact-form" id="contact-us">
            <p>At Elevvia, we specialize in creating exceptional digital experiences. Our team of seasoned developers, designers, and AI experts is dedicated to bringing your vision to life.</p>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="serviceType">Type of Service</label>
                <select
                  id="serviceType"
                  name="serviceType"
                  value={formData.serviceType}
                  onChange={handleChange}
                  className="form-control"
                  required
                >
                  <option value="">Select a service</option>
                  <option value="Web Development">Web Development</option>
                  <option value="iOS App">iOS App</option>
                  <option value="AI">AI</option>
                  <option value="UI/UX Design">UI/UX Design</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="details">Details about Work</label>
                <textarea
                  id="details"
                  name="details"
                  value={formData.details}
                  onChange={handleChange}
                  className="form-control"
                  rows="2"
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary form-contactUs-btn">Submit</button>
            </form>
            {/* {message && <p className='message-sent-alert'>{message}</p>} */}
          </div>
        </div>
      </header>
      {/* <Footer/> */}
    </div>
  );
}

export default ContactUs;
