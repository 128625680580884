import React, { useEffect, useState } from "react";
import '../componentsCss/NavBar.css';
import 'bootstrap/dist/css/bootstrap.css';

function NavBarView() {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) { // Adjust the scroll value as needed
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToContactUs = () => {
        document.getElementById('contact-us').scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <nav className={`navbar navbar-expand-lg navbar-light fixed-top ${scrolled ? 'scrolled' : ''}`} data-bs-theme="dark">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/home">
                        <img src="logo.png" alt="Logo" width="90" height="40" className="d-inline-block align-text-top" />
                    </a>
                    <button className="contactUs-btn" onClick={scrollToContactUs}>
                        Contact Us
                    </button>
                </div>
            </nav>
        </>
    );
}

export default NavBarView;
