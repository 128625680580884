import React from "react";
import '../componentsCss/ProjectsView.css'
import SneakerAppPage from "./SneakerAppPage";
import TourView from "./TourProjectView";
import UiProjectView from "./UiProject";

function ProjectView(){
    return(
        <div className="projects-main">
               
            <h1>Projects</h1>
            <p>Your satisfaction is our top priority. We work closely with you to ensure that your vision is realized.</p>

            <div className="projects">
                <SneakerAppPage />
                <TourView />
                <UiProjectView />
            </div>
        </div>
    );
}

export default ProjectView;