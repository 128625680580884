// demoCard.js
import React from 'react';
import '../componentsCss/upperDemoCard.css'; 

function upperDemoCardView() {
    return (
        <div className="demo-card">
        </div>
    );
}

export default upperDemoCardView;
